<template>
  <div>
    <div class="users-list" :class="{ details: showResident }">
      <div
        v-for="item in items"
        :key="item.id"
        class="user-item"
        id="user-item"
        :class="{
          active: item.personal_account === resident.personal_account,
          red: item.debt,
        }"
        @click="showResidentModal(item)"
      >
        <div class="avatar">
          <img v-if="item.avatar" :src="item.avatar" alt="" />
          <img v-else src="@/assets/images/avatar-white.svg" alt="" />
        </div>
        <div class="text">
          <div class="account">{{ item.personal_account }}</div>
          <div class="address-row">
            <div class="address">
              <div class="type">Блок</div>
              <div class="num">{{ item.block }}</div>
            </div>
            <div class="address">
              <div class="type">Подъезд</div>
              <div class="num">{{ item.entrance }}</div>
            </div>
            <div class="address">
              <div class="type">Квартира</div>
              <div class="num">{{ item.number }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-details" v-if="showResident">
      <div class="side-modal-wrap">
        <div class="hide" @click="closeResident">
          Скрыть <img src="@/assets/images/house-arrow.svg" alt="" />
        </div>
        <div class="executor-info">
          <div class="avatar">
            <img v-if="resident.avatar" :src="resident.avatar" alt="" />
            <img v-else src="@/assets/images/avatar-lg.svg" alt="" />
          </div>
          <div class="text">
            <div class="name">{{ resident.name }}</div>
            <div class="address-row">
              <div class="address">
                <div class="type">Блок</div>
                <div class="num">{{ resident.block }}</div>
              </div>
              <div class="address">
                <div class="type">Подъезд</div>
                <div class="num">{{ resident.entrance }}</div>
              </div>
              <div class="address">
                <div class="type">Этаж</div>
                <div class="num">{{ resident.floor }}</div>
              </div>
              <div class="address" v-if="resident.number">
                <div class="type">Квартира</div>
                <div class="num">{{ resident.number }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="executor-description">
          <div class="item">
            <div class="left">Телефон</div>
            <div class="right" v-if="resident.user">
              {{ resident.user.phone | VMask("+# (###) ###-##-##") }}
            </div>
          </div>
          <div class="item">
            <div class="left">E-mail</div>
            <div class="right" v-if="resident.user">
              {{ resident.user.email || "-" }}
            </div>
          </div>
        </div>
        <div class="leave-comment">
          <textarea placeholder="Напишите комментарий"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { personalAccountsApi, personalAccountsIdApi } from "@/api/users";
import { getUser } from "@/api";

export default {
  props: {
    filters: Object,
  },
  data: () => ({
    items: [],
    resident: {},
    showResident: false,
  }),
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler() {
        const filters = {};
        Object.keys(this.filters).map((key) => {
          if (!!this.filters[key]) {
            filters[key] = this.filters[key];

            // TEMPORARY FIX WHEN BACKEND WILL BE FIXED!!!
            if (key === "search") {
              filters.personal_account = this.filters[key];
            }
          }
        });
        this.getResidents(filters);
      },
    },
  },
  created() {
    this.init();
  },
  computed: {
    type() {
      return this.$route.name.split(".")[1];
    },
  },
  methods: {
    init() {
      this.getResidents();
    },
    async getResidents(filters) {
      this.items = [];
      await personalAccountsApi(filters).then((res) => {
        this.items = res.data.map((data) => ({
          id: data.building?.id,
          name: data.owner,
          block: data.building?.block,
          number: data.building?.number,
          floor: data.building?.floor,
          entrance: data.building?.entrance,
          avatar: data.image,
          debt: data.debt,
          // user: Number.parseInt(data?.user?.id),
          user: data?.user,
          personal_account: data.personal_account,
        }));
      });
    },
    showResidentModal(item) {
      this.resident = item;
      if (this.showResident === true) {
        this.showResident = false;
        this.showResident = true;
      } else {
        this.showResident = !this.showResident;
        this.$emit("resizeTabsRow");
      }
    },
    closeResident() {
      this.resident = {};
      this.showResident = false;
      this.$emit("resizeTabsRow");
    },
  },
};
</script>

<style>
</style>
